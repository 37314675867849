import React from "react";
import { useRef } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

const FieldCheckBoxArray = ({
  type,
  name,
  value,
  selected,
  label,
  message,
  disabled,
  required,
  ...props
}) => {
  const id = useRef(nanoid());

  if (name === undefined) {
    name = id.toString();
  }

  return (
    <div>
      {label && <span className="dark:text-white">{label}</span>}
      <div>
        <ul className="list-none">
          {props.values.map((val) => {
            return (
              <li className="list-none">
                <div className="flex">
                  <input
                    id={id.current}
                    name={name}
                    disabled={disabled}
                    required={required}
                    defaultValue={val[0]}
                    defaultChecked={selected}
                    type="checkbox"
                    className="dark:bg-zinc-700"
                  />
                  <label className="pl-2">{val[0]}</label>
                  <label className="pl-2">{val[1]}</label>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

FieldCheckBoxArray.propTypes = {
  type: PropTypes.oneOf(["radio", "check"]),
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FieldCheckBoxArray;
