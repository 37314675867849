import React from "react";
import PropTypes from "prop-types";
import ServerTable from "../table/ServerTable.js";

function Table(props) {
  return (
    <div className="dark:dark-mode">
      {props.path ? <div className="mb-2">{props.path}</div> : ""}
      <div className="my-3 flex content-center">
        {props.title ? (
          <h1 className="text-4xl my-0 mr-1.5">{props.title}</h1>
        ) : (
          ""
        )}
        {props.actions ? props.actions : ""}
      </div>
      <div className="w-full bg-white p-4 dark:dark-mode">
        {props.content}
        <ServerTable key={props.servertable.endpoint} {...props.servertable} />
      </div>
    </div>
  );
}

Table.propTypes = {
  path: PropTypes.array,
  title: PropTypes.string,
  content: PropTypes.array,
  servertable: PropTypes.object,
  actions: PropTypes.array,
};

export default Table;
