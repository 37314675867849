import React from "react";
import THead from "./head/THead";
import TBody from "./body/TBody";
import propTypes from "prop-types";

const Table = ({ hideHead, ...props }) => {
  return (
    <table className="w-full max-w-full overflow-auto">
      <THead hide={hideHead} {...props} />
      <TBody {...props} />
    </table>
  );
};

Table.propTypes = {
  hideHead: propTypes.bool,
};

export default Table;
