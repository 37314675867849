import React, { useState, useEffect, useRef } from "react";
import instance from "../../axiosConfig";
import { Base64 } from "js-base64";
import Button from "../interactive/Button";
import MarkDown from "../markdown/MarkDown";
import { XCircle } from "react-feather";
import { nanoid } from "nanoid";
import propTypes from "prop-types";

const FieldSearch = ({
  type,
  name,
  value,
  label,
  message,
  endPoint,
  actions,
  options,
  ...props
}) => {
  const id = useRef(nanoid());
  // Search delay in ms.
  const delay = 200;
  const searchEndPoint = endPoint || "/search/item";
  const [input] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [onlyActive, setOnlyActive] = useState(true);
  const [valueList, setValueList] = useState([]);
  const [searchData, setSearchData] = useState({ body: [] });
  let abortController = new window.AbortController();

  const delayChange = (e) => {
    const delayDebounceFn = setTimeout(() => {
      if (e.target.value === "" || e.target.value === null) {
        setSearchData({ body: [] });
        return;
      }
      setSearchTerm(e.target.value);
    }, delay);
    return () => clearTimeout(delayDebounceFn);
  };

  useEffect(() => {
    if (props.table !== []) {
      const items = props.table.map((item) => {
        return { name: item, _id: item };
      });
      setList(items);
    }
  }, [props.table]);

  useEffect(() => {
    if (searchTerm === "") {
      return;
    }

    abortController.abort();
    abortController = new window.AbortController();

    instance
      .get(searchEndPoint, {
        signal: abortController.signal,
        params: {
          filters: Base64.encode(
            JSON.stringify({ name: searchTerm, onlyActive: onlyActive }),
          ),
        },
      })
      .then((response) => {
        setSearchData(response.data.results);
      })
      .catch((response) => {
        console.error("Error in search", response);
      });
  }, [searchTerm, searchEndPoint, onlyActive]);

  const handleClick = (path) => {
    instance
      .get(path)
      .then((response) => {
        console.log(response);
      })
      .catch(() => {
        console.error("Error in item action");
      });
  };

  const removeFromList = (e, val) => {
    e.preventDefault();
    const newList = list.filter((value) => value._id !== val);
    setList(newList);
  };

  const handleAddClick = (data) => {
    if (!list.includes(data)) {
      setList([...list, data]);
    }
    console.log(list);
  };

  useEffect(() => {
    const values = list.map((e) => e._id);
    setValueList(values);
  }, [list]);

  return (
    <div className="field">
      <input type="hidden" value={valueList} name={name} id={id} />
      <div className="flex">
        {options.onlyActive ? (
          // <div className="w-1/5 pt-6">
          <div style={{ width: "120px" }} className="pt-6">
            <div className="flex">
              <label className="dark:text-white pr-2">Vain aktiiviset</label>
              <input
                type="checkbox"
                id="active"
                name="active"
                checked={onlyActive}
                onChange={() => setOnlyActive(!onlyActive)}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="w-4/5" style={{ maxWidth: "500px" }}>
          {label ? (
            <label htmlFor={id} className="dark:text-white">
              {label}
            </label>
          ) : null}
          <input
            key={input}
            autoFocus
            defaultValue={input}
            type={type}
            onChange={delayChange}
            className="dark:bg-zinc-700"
          />
        </div>
      </div>

      {message ? <p className="dark:text-white">{message}</p> : null}
      <div className="flex flex-wrap" style={{ maxWidth: "300px" }}>
        {list.map((value, key) => {
          return (
            <div key={key} className="w-2/5 flex-auto">
              <button onClick={(e) => removeFromList(e, value._id)}>
                <div className="inline-flex ml-2 text-xs items-center font-bold leading-sm uppercase bg-red-200 text-red-700 rounded-full">
                  <span className="m-1">{value.name}</span>
                  <XCircle className="m-0.5" />
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <div>
        <table>
          <tbody>
            {searchData.body.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="dark:odd:bg-zinc-800 dark:even:bg-gray-900 dark:text-whit"
                >
                  {Object.keys(item).map((data, key) => {
                    if (data === "_id") {
                      if (actions && actions.length > 0) {
                        return actions.map((action, key) => {
                          if (action.type === "select") {
                            return (
                              <td key={key}>
                                <Button
                                  key={key}
                                  feel={action.feel}
                                  text={action.text}
                                  onClick={() => setList([item])}
                                />
                              </td>
                            );
                          }
                          if (action.type === "link") {
                            return (
                              <td key={key}>
                                <Button
                                  key={key}
                                  type={action.type}
                                  feel={action.feel}
                                  text={action.text}
                                  link={action.link + item[data]}
                                />
                              </td>
                            );
                          }
                          return (
                            <td key={key}>
                              <Button
                                key={key}
                                feel={action.feel}
                                text={action.text}
                                onClick={() =>
                                  handleClick(action.link + item[data])
                                }
                              />
                            </td>
                          );
                        });
                      } else {
                        return (
                          <td key={key}>
                            <Button
                              key={key}
                              feel="primary"
                              text="Lisää"
                              onClick={() => handleAddClick(item)}
                            />
                          </td>
                        );
                      }
                    }
                    return (
                      <td key={key}>
                        <MarkDown>{item[data]}</MarkDown>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

FieldSearch.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  value: propTypes.string,
  label: propTypes.string,
  message: propTypes.string,
  endPoint: propTypes.string,
  actions: propTypes.array,
  table: propTypes.array,
  options: propTypes.oneOfType([propTypes.array, propTypes.object]),
};

export default FieldSearch;
