import React from "react";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import Flag from "react-flagpack";
import { isoToCountryCode } from "flagpack-core";
import * as Icon from "react-feather";
import { AsYouType } from "libphonenumber-js";

class FieldPhone extends React.Component {
  constructor(props) {
    super(props);
    this.id = nanoid();
    const asYouType = new AsYouType();
    const phoneNumber = asYouType.input(
      this.props.value ? this.props.value : "",
    );
    this.state = {
      value: phoneNumber,
      focusClass: null,
      countryCode: asYouType.country,
      fails: !(
        asYouType.isInternational() &&
        asYouType.isPossible() &&
        asYouType.isValid()
      ),
      status: {
        isInternational: asYouType.isInternational(),
        isPossible: asYouType.isPossible(),
        isValid: asYouType.isValid(),
      },
    };
    this.message = this.props.message ? (
      <p className="text-sm">{this.props.message}</p>
    ) : null;

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getFlag = this.getFlag.bind(this);
    this.getStatus = this.getStatus.bind(this);
  }

  handleChange(event) {
    const asYouType = new AsYouType();
    const phoneNumber = asYouType.input(event.target.value);
    this.setState({
      value: phoneNumber,
      countryCode: asYouType.country,
      fails: !(
        asYouType.isInternational() &&
        asYouType.isPossible() &&
        asYouType.isValid()
      ),
      status: {
        isInternational: asYouType.isInternational(),
        isPossible: asYouType.isPossible(),
        isValid: asYouType.isValid(),
      },
    });
  }

  handleFocus(event) {
    this.setState({
      focusClass:
        "border-l border-indigo-400 ring ring-indigo-300 ring-opacity-50",
    });
  }

  handleBlur(event) {
    if (
      this.props.required &&
      (this.state.status.isPossible === false ||
        this.state.status.isInternational === false)
    ) {
      this.setState({
        focusClass: "border-l border-red-400 ring ring-red-300 ring-opacity-50",
      });
    } else {
      this.setState({ focusClass: null });
    }
  }

  getFlag(code) {
    let flag = (
      <Flag code={code} hasBorder hasDropShadow className="!w-6 !h-5" />
    );
    try {
      const testIsoCode = isoToCountryCode(code); // eslint-disable-line
    } catch (error) {
      flag = <Icon.HelpCircle className="h-4" />;
      if (window.localStorage.getItem("debug")) {
        console.error(error);
      }
    }
    return flag;
  }

  getStatus(props) {
    if (props.isInternational === false) {
      return <Icon.AlertOctagon className="h-5 text-red-500" />;
    }
    if (props.isPossible === false) {
      return <Icon.AlertTriangle className="h-5 text-red-500" />;
    }
    if (props.isValid === false) {
      return <Icon.AlertCircle className="h-4 text-yellow-500" />;
    }
    // TODO: Tell to user what does these alerts means.
  }

  render() {
    return (
      <div className="block mt-2" key={this.props.id}>
        {this.props.label ? (
          <label htmlFor={this.id}>{this.props.label}</label>
        ) : null}
        <div
          className={
            "flex flex-initial shadow-sm my-1.5 border-l rounded-md " +
            this.state.focusClass
          }
        >
          <div
            title={this.state.countryCode}
            className={
              "grid place-items-center py-1 px-2 block w-min rounded-l-md border border-r-0 border-gray-300 " +
              (this.props.disabled
                ? "bg-gray-300 opacity-50 cursor-not-allowed"
                : null)
            }
          >
            {this.getFlag(this.state.countryCode)}
          </div>
          <input
            id={this.id}
            type="tel"
            disabled={this.props.disabled}
            required={this.props.required}
            value={this.state.value}
            name={this.props.name}
            onInput={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            className="outline-none py-1 pl-0 pr-0 px-2 block w-full border border-l-0 border-r-0 border-gray-300 disabled:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed dark:text-white dark:bg-zinc-700"
          />
          <div
            className={
              "grid place-items-center py-1 px-2 block w-min rounded-r-md border border-l-0 border-gray-300 " +
              (this.props.disabled
                ? "bg-gray-300 opacity-50 cursor-not-allowed"
                : null)
            }
          >
            {this.state.fails ? this.getStatus(this.state.status) : null}
          </div>
        </div>
        {this.message}
      </div>
    );
  }
}

FieldPhone.propTypes = {
  label: PropTypes.string,
  id: PropTypes.any,
  value: PropTypes.string,
  name: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FieldPhone;
