import React from "react";
import propTypes from "prop-types";

function RowPlaceHolder({ head = [] }) {
  /* Loops through headers and gets column lengths. Return biggest one. */
  const columnCount = () => {
    const lengths = head.map((header) => {
      if (!Array.isArray(header)) {
        return 0;
      }
      return header.length;
    });
    return Math.max(...lengths);
  };

  if (!Array.isArray(head)) {
    return "";
  }

  return (
    <tr>
      {Array.apply(null, Array(columnCount())).map((cell, index) => (
        <td key={index}>
          <div className="bg-gray-400 h-4 block rounded-sm " />
        </td>
      ))}
    </tr>
  );
}

RowPlaceHolder.propTypes = {
  head: propTypes.array,
};

export default RowPlaceHolder;
