import React from "react";
import PropTypes from "prop-types";

import Row from "./Row";

function THead({ head, hide, ...props }) {
  if (hide) {
    return <thead />;
  }
  return (
    <thead>
      {head.map((header, index) => {
        return <Row key={index} header={header} {...props} />;
      })}
    </thead>
  );
}

THead.propTypes = {
  head: PropTypes.array,
  hide: PropTypes.bool,
  sortedBy: PropTypes.string,
  onSortChange: PropTypes.func,
};

export default THead;
