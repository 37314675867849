import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { useSelector } from "react-redux";

const Field = ({
  type,
  name,
  value,
  label,
  message,
  disabled,
  required,
  min,
  max,
  rows,
  ...props
}) => {
  const id = nanoid();
  const [input, setInput] = useState("");
  const darkMode = useSelector((state) => state.darkMode.theme);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    setInput((value.toString() + "").trim());
  }, [value]);

  return (
    <div key={props.id} data-color-mode={darkMode}>
      {label ? <label htmlFor={props.id}>{label}</label> : null}
      <MDEditor
        id={props.id}
        defaultValue={input}
        name={name}
        value={input}
        onChange={setInput}
        commands={[
          commands.bold,
          commands.italic,
          commands.strikethrough,
          commands.hr,
          commands.title,
          commands.divider,
          commands.link,
          commands.divider,
          commands.unorderedListCommand,
        ]}
      />
      <textarea
        className="hidden"
        id={id}
        value={input}
        name={name}
        onChange={(e) => e.preventDefault()}
      />
    </div>
  );
};

Field.propTypes = {
  type: PropTypes.oneOf([
    "hidden",
    "text",
    "textarea",
    "tel",
    "email",
    "password",
    "url",
    "file",
    "number",
    "date",
    "month",
    "week",
    "datetime",
    "color",
    "datetime-local",
  ]),
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default Field;
