import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import instance from "./../axiosConfig";

/* Fields */
import FormFields from "./FormFields";

/* Button */
import Button from "./interactive/Button";

function Form({ ...props }) {
  const [originalForm, setOriginal] = useState({});
  const [form, setForm] = useState({});

  useEffect(() => {
    setOriginal(props.form);
    setForm(props.form);
  }, [props.form]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new window.FormData(event.target);

    // for (const value of formData.values()) {
    //   console.log(value);
    // }

    const object = {};
    formData.forEach((value, key) => {
      if (!Reflect.has(object, key)) {
        object[key] = value;
        return;
      }
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
      }
      object[key].push(value);
    });
    const jsonFormData = JSON.stringify(object);

    const operation =
      form.buttons[
        parseInt(
          event.nativeEvent.submitter.getAttribute("data-operation-index"),
        )
      ].operation;
    if (operation["@type"] === "hydra:Operation") {
      instance({
        method: operation["hydra:method"].toLowerCase(),
        url: operation["@id"],
        data: jsonFormData,
      })
        .then((response) => {
          if (response.headers["content-type"] === "application/csv") {
            handleCSV(response);
          }
          if (response.data.actions) {
            handleActions(response.data.actions);
          }
        })
        .catch(function (error) {
          if (error.response?.data?.actions) {
            handleActions(error.response.data.actions);
          }
        });
    }
  };

  const handleCSV = (response) => {
    try {
      let filename = "";

      filename = response.request.responseURL.split("/").pop();
      filename = filename.split("?")[0];

      // UTF-8
      const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([BOM, response.data], { type: "application/csv" });
      const href = URL.createObjectURL(blob);
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", filename); // or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.log(e);
    }
  };

  const handleActions = (actions) => {
    actions.forEach((action) => {
      switch (action.type) {
        case "reset-from":
          // TODO changes form values to response form values.
          console.log("Form reset-from action");
          setForm(JSON.parse(action.data.content).form);
          break;
        case "reset-from-defaults":
          // TODO changes form values to response form values.
          console.log("Form reset-from-defaults action");
          setForm(JSON.parse(action.data.content).form);
          break;
        default:
          console.log("Form action handler.");
      }
    });
  };

  const resetForm = (e) => {
    e.preventDefault();
    setForm({});
    setTimeout(() => setForm(originalForm), 40);
  };

  const buttons = () => {
    return form.buttons.map((button, index) => {
      if (button.type === "reset") {
        return (
          <Button
            key={index}
            id={index}
            operationIndex={index}
            feel={button.style}
            {...button}
            onClick={resetForm}
          />
        );
      }
      return (
        <Button
          key={index}
          id={index}
          operationIndex={index}
          feel={button.style}
          {...button}
        />
      );
    });
  };

  if (!form.fields || !form.buttons) {
    if (!form) {
      console.error("No fields or all the necessary data set.", form);
    }
    return "";
  }

  // check if form has reset button. Else insert one.
  const resetButton = form.buttons.some((button) => {
    return button.type === "reset";
  });

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormFields fields={form.fields} />
        {buttons()}
        {!resetButton && (
          <Button
            text="Tyhjennä kaavake"
            feel="dangerous"
            onClick={resetForm}
          />
        )}
      </form>
    </div>
  );
}

Form.propTypes = {
  form: PropTypes.object,
};

export default Form;
