import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import Button from "../interactive/Button";
import { XCircle } from "react-feather";

const FieldListSelect = ({ name, label, value, message, ...props }) => {
  const id = useRef(nanoid());
  const [list, setList] = useState(value);
  const [inputValue, setInputValue] = useState("");

  const removeFromList = (val) => {
    const newList = list.filter((value) => value !== val);
    setList(newList);
  };

  const addToList = (e) => {
    e.preventDefault();
    const newList = list;
    newList.push(inputValue);
    setList(newList);
    setInputValue("");
  };

  const valueLabel = (value) => {
    const found = props.choices.find((choice) => choice.value === value);
    if (found) {
      return found.label;
    }
    return value;
  };

  useEffect(() => {
    setInputValue(props.choices[0].value);
  }, [props.choices]);

  return (
    <label key={props.id} htmlFor={id.current} className="field">
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}
        </label>
      ) : null}
      <input type="hidden" value={list} name={name} id={id} />
      <div className="inline-flex gap-5">
        <Button text="Lisää" feel="primary" onClick={addToList} />
        <select
          onChange={(e) => setInputValue(e.target.value)}
          className="dark:bg-zinc-700"
        >
          {props.choices.map((choice, index) => (
            <option key={`${index}-${choice.value}`} value={choice.value}>
              {choice.label}
            </option>
          ))}
        </select>
      </div>
      <ul className="m-2">
        {list.map((value, key) => {
          return (
            <li key={key} className="inline-flex">
              <button onClick={() => removeFromList(value)}>
                <div className="inline-flex ml-2 text-xs items-center font-bold leading-sm uppercase bg-red-200 text-red-700 rounded-full">
                  <span className="m-1">{valueLabel(value)}</span>
                  <XCircle className="m-0.5" />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
      {message}
    </label>
  );
};

FieldListSelect.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  choices: PropTypes.array,
  message: PropTypes.string,
};

export default FieldListSelect;
