import React from "react";
import PropTypes from "prop-types";
import Field from "./fields/Field";
import Button from "./interactive/Button";

class OTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(event);
  }

  render() {
    return (
      <div className="flex h-screen items-center justify-center dark:dark-mode">
        <div>
          <h1>Knupu</h1>
          <form onSubmit={this.handleSubmit}>
            <Field name="otp" label="Kertakäyttökoodi" required />
            <Button type="submit" value="Submit" text="Lähetä" />
          </form>
        </div>
      </div>
    );
  }
}

OTP.propTypes = {
  onSubmit: PropTypes.func,
};

export default OTP;
