import React from "react";
import PropTypes from "prop-types";
import Fields from "./../FormFields";

/* Button */
import Button from "./Button";

function GraphFilters({
  form = {},
  title = "Rajaa",
  _cancel = "Peruuta",
  _clear = "Nollaa",
  info = "%total suodatinta aktiivisena",
  display = false,
  ...props
}) {
  if (!form) {
    console.error("No form found for filters.");
    return "";
  }

  return (
    <div>
      <div className="">
        <form id={props.id} onSubmit={props.handleApply}>
          <h2>{props.title}</h2>
          <div>
            <Fields fields={props.fields} />
            <Button type="submit" feel="green" text={title} />
          </div>
        </form>
      </div>
    </div>
  );
}

GraphFilters.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  _cancel: PropTypes.string,
  _clear: PropTypes.string,
  info: PropTypes.string,
  display: PropTypes.bool,
  handleClose: PropTypes.func,
  handleApply: PropTypes.func,
  fields: PropTypes.array,
};

export default GraphFilters;
