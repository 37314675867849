import React from "react";
import PropTypes from "prop-types";

import "./Navigation.css";

import { Link } from "react-router-dom";

function NavImage({
  source = "https://upload.wikimedia.org/wikipedia/commons/0/06/AGPLv3_Logo.svg",
  alt = "Logo",
  link = "https://kiitoskauppa.fi",
  size = "half",
}) {
  return (
    <li className={size === "half" ? " w-3/6 inline-block p-2" : " block m-2"}>
      {link && (
        <Link to={link}>
          <img alt={alt} src={source} className="w-full max-h-24" />
        </Link>
      )}
      {!link && <img alt={alt} src={source} className="w-full max-h-24" />}
    </li>
  );
}
NavImage.propTypes = {
  size: PropTypes.string,
  source: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
};

export default NavImage;
