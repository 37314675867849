import React, { useEffect, useState } from "react";
import _ from "underscore";
import ReportFilters from "./ReportFilters";
import instance from "../../axiosConfig";
import { Base64 } from "js-base64";
import { useSelector } from "react-redux";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import PropTypes from "prop-types";
import Keyfigures from "./Keyfigures";
import { light } from "../../store/features/darkmode";
import store from "../../store/store";

function ReportGraph({ endpoint, ...props }) {
  const [filters, setFilters] = useState({});
  const defaultParams = {};
  const [params, setParams] = useState(defaultParams);
  const [disabled, setDisabled] = useState(false);
  const [body, setBody] = useState([]);
  const [meta, setMeta] = useState({});
  const [keyfigures, setKeyfigures] = useState([]);
  const [initialState, setInitialState] = useState(true);
  const redirect = useSelector((state) => state.redirect.value);
  const darkMode = useSelector((state) => state.darkMode.theme);
  let labelColor;

  /** Reload graph data on change, and reset pagination. */
  useEffect(() => {
    load();
  }, [filters, params, redirect]);

  useEffect(() => {
    if (!initialState) {
      load();
    }
    setInitialState(false);
  }, [initialState]);

  const updateParams = (newParams) => {
    setParams({
      ...params,
      ...newParams,
    });
  };

  const month = [
    "Tammikuu",
    "Helmikuu",
    "Maaliskuu",
    "Huhtikuu",
    "Toukokuu",
    "Kesäkuu",
    "Heinäkuu",
    "Elokuu",
    "Syyskuu",
    "Lokakuu",
    "Marraskuu",
    "Joulukuu",
  ];

  const load = () => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    store.dispatch({ type: "loading/loading" });
    instance
      .get(endpoint, {
        params: {
          ...params,
          filters:
            _.size(filters) > 0 ? Base64.encode(JSON.stringify(filters)) : null,
        },
      })
      .then(function (response) {
        let graphData = {};
        if (
          response.data.graph.meta.interval === "month" ||
          response.data.graph.meta.interval === "week"
        ) {
          graphData = response.data.graph.body.sort((a, b) => {
            return a.x - b.x;
          });
        } else {
          graphData = response.data.graph.body;
        }

        setBody(graphData);
        setMeta(response.data.graph.meta);
        setKeyfigures(response.data.graph.keyfigures);
        setDisabled(false);
      })
      .catch(function (error) {
        console.log(error);
        setDisabled(false);
      })
      .then(function () {
        store.dispatch({ type: "loading/done" });
      });
  };

  if (darkMode === "light") {
    labelColor = "#000000";
  } else {
    labelColor = "#ffffff";
  }

  return (
    <div key={endpoint} className={disabled ? "animate-pulse cursor-wait" : ""}>
      <ReportFilters
        key={endpoint}
        {...props}
        params={params}
        onParamChange={updateParams}
        onFilterChange={setFilters}
      />
      <div className="mt-3">{meta.dateInformation}</div>
      <div className="relative ml-0.5 w-full p-4">
        <VictoryChart
          domainPadding={10}
          theme={VictoryTheme.material}
          width={1000}
        >
          <VictoryAxis
            tickFormat={body.map((d) =>
              meta.interval === "month" ? month[d.x - 1] : d.x,
            )}
            tickValues={body.map((d) => d.x)}
            style={{
              tickLabels: { fill: labelColor, width: 15 },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(x) =>
              x >= 1000000 ? `${x / 1000000}M €` : `${x / 1000}t €`
            }
            style={{
              tickLabels: { fill: labelColor, width: 15 },
            }}
          />
          <VictoryBar
            labelComponent={<VictoryTooltip />}
            sortKey="x"
            data={body}
            x="x"
            y="y"
            style={{
              data: { fill: "#f64747", width: 15 },
            }}
          />
        </VictoryChart>
        <div className="absolute right-0 bottom-0 mr-2 text-sm text-gray-500">
          {meta.cacheInformation ? meta.cacheInformation : ""}
        </div>
      </div>
      <div className="container px-5 mx-auto">
        <div className="flex flex-wrap -m-4">
          <Keyfigures figures={keyfigures} />
        </div>
      </div>
    </div>
  );
}

ReportGraph.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

export default ReportGraph;
