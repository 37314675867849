import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import Button from "./interactive/Button";
import store from "../store/store";
import useThemeHook from "../Hook/ThemeHook";

function TopBar({
  topBar = [
    {
      type: "profile",
      thumbnail: "/default.png",
      userName: "Testi Testinen",
      organization: "Oy Testeri Ab",
    },
    {
      type: "profile",
      thumbnail:
        "https://static.elisa.com/v2/image/2tqybbhjs47b/3vinbiamfIxFyLQoUMx4nf/yritysguru-auttaa-nopeasti.jpg?w=1920&h=1920&fm=jpg",
      userName: "0600 900 200",
      organization: "Elisa Yritysguru",
    },
  ],
}) {
  const [colorTheme, setTheme] = useThemeHook();
  const [darkMode, setDarkMode] = useState(colorTheme === "light");

  const toggleDarkMode = (checked) => {
    setTheme(colorTheme);
    setDarkMode(!darkMode);
  };

  const handleLogOut = () => {
    store.dispatch({ type: "logout/logout" });
  };

  const handleToggleHistory = () => {
    store.dispatch({ type: "notification/toggleModal" });
  };

  const createBar = (arr) => {
    return arr.map((item, index) => {
      switch (item.type) {
        case "profile": {
          return (
            <div
              key={index}
              className="grid gap-0 items-center"
              style={{ gridTemplateAreas: '"left top" "left bottom"' }}
            >
              {item.thumbnail ? (
                <img
                  className="object-cover h-9 w-9 rounded-full mr-3"
                  style={{ gridArea: "left" }}
                  src={item.thumbnail}
                  alt=""
                />
              ) : (
                ""
              )}
              {item.userName ? (
                <h1
                  style={{ gridArea: "top" }}
                  className={
                    "font-light text-xl" +
                    (item.organization ? " -mb-1 -mt-1" : "")
                  }
                >
                  {item.userName}
                </h1>
              ) : (
                ""
              )}
              {item.organization ? (
                <h2
                  style={{ gridArea: "bottom" }}
                  className="font-medium text-sm m-0"
                >
                  {item.organization}
                </h2>
              ) : (
                ""
              )}
            </div>
          );
        }
        default:
          console.log(item.type);
      }
      return "";
    });
  };

  return (
    <>
      <div
        className="w-full relative p-3 border-b flex h-14 justify-between items-center"
        style={{ gridArea: "topbar" }}
      >
        <p>
          {window.knupu && window.knupu.version
            ? `Client: ${window.knupu.version}`
            : ""}{" "}
          {window.knupu && window.knupu.serverVersion
            ? `Server: ${window.knupu.serverVersion}`
            : ""}
        </p>
        {topBar ? createBar(topBar) : ""}
        <div className="inline-flex items-center">
          {darkMode ? (
            <Icon.ToggleRight className="w-5 m-2" onClick={toggleDarkMode} />
          ) : (
            <Icon.ToggleLeft className="w-5 m-2" onClick={toggleDarkMode} />
          )}
          <div onClick={handleToggleHistory}>
            <Icon.Inbox className="w-5 m-2" />
          </div>
          <Button
            ariaLabel="Log out"
            text={<Icon.LogOut />}
            feel="purple"
            onClick={handleLogOut}
          />
        </div>
      </div>
    </>
  );
}

TopBar.propTypes = {
  topBar: PropTypes.array,
};

// export default TopBar;
export default TopBar;
