import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import propTypes from "prop-types";

function Image({ source, alt, size }) {
  const width = size?.width || "300";
  const height = size?.height || "300";
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [source]);

  const placeholder = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={"0 0 " + width + " " + height}
      >
        <rect fill="#ddd" width={width} height={height} />
        <text
          fill="rgba(0,0,0,0.5)"
          fontFamily="sans-serif"
          fontSize="30"
          dy="10.5"
          fontWeight="bold"
          x="50%"
          y="50%"
          textAnchor="middle"
        >
          {width}x{height}
        </text>
      </svg>
    );
  };

  const image = () => {
    const baseUrl = window.localStorage.getItem("baseURL") + "/api";
    if (source.startsWith("http://") || source.startsWith("https://")) {
      return (
        <img
          src={source}
          alt={alt}
          width={width}
          height={height}
          onError={setError}
        />
      );
    } else {
      return (
        <img
          src={baseUrl + source}
          alt={alt}
          width={width}
          height={height}
          onError={setError}
        />
      );
    }
  };

  return <div>{error ? placeholder() : image()}</div>;
}

Image.propTypes = {
  source: PropTypes.string,
  alt: PropTypes.string,
  size: propTypes.object,
};

export default Image;
