import React from "react";
import PropTypes from "prop-types";
import FieldEmail from "./fields/FieldEmail";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit(event);
  }

  render() {
    return (
      <div>
        <h1>Knupu</h1>
        <form onSubmit={this.handleSubmit}>
          <FieldEmail
            name="email"
            label="Sähköpostiosoite"
            required
            message="Lähetämme sinulle linkin, josta klikkaamalla pääset kirjautumaan sille laitteelle, jossa linkkiä klikkaat."
          />
          <input type="submit" value="Submit" />
        </form>
      </div>
    );
  }
}

Auth.propTypes = {
  onSubmit: PropTypes.func,
};

export default Auth;
