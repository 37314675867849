import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import { nanoid } from "nanoid";
import Button from "../interactive/Button";

const FieldExcel = ({ name, label }) => {
  const id = nanoid();
  const [file, setFile] = useState(null);
  const [fileID, setFileID] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (file === null) return;
    // Read file data
    const workBook = XLSX.read(file, { type: "binary" });
    // Get first sheet name
    const sheetName = workBook.SheetNames[0];
    // Read sheet data
    let tempdata = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName], {
      raw: false,
      header: 1,
    });
    // Filter out empty rows
    tempdata = tempdata.filter((row, key) => {
      return row.length > 0;
    });
    tempdata = tempdata.map((row) => {
      return row[0];
    });
    setData(() => [...tempdata]);
    setFileID(nanoid());
  }, [file]);

  /** Read file from input */
  const onChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length === 0) return;
    const reader = new window.FileReader();
    reader.onload = () => {
      setFile(() => reader.result);
    };
    reader.readAsBinaryString(files[0]);
  };

  const handleItemChange = (e, key) => {
    e.preventDefault();
    const editedData = data;
    editedData[key] = e.target.value;
    setData(() => [...editedData]);
  };

  const removeItem = (e, key) => {
    e.preventDefault();
    let editedData = data;
    editedData.splice(key, 1);
    setData(() => [...editedData]);
  };

  return (
    <div className="field">
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}
        </label>
      ) : null}
      <input
        type="file"
        id="recipient-sheet"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={onChange}
        className="dark:bg-zinc-700"
      />
      <div key={fileID}>
        <table className="w-full max-w-full overflow-auto">
          <thead>
            <tr>
              <th>Tiedoston sisältö</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, key) => {
              // if (key === 0) return null;
              return (
                <tr key={item}>
                  <td>
                    <input
                      type="text"
                      defaultValue={item}
                      onChange={(e) => handleItemChange(e, key)}
                      className="dark:bg-zinc-700"
                    />
                  </td>
                  <td>
                    <Button
                      onClick={(e) => removeItem(e, key)}
                      text="Poista"
                      feel="danger"
                    ></Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <input type="hidden" name={name || ""} value={data || ""} id={id} />
    </div>
  );
};

FieldExcel.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default FieldExcel;
